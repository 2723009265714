import { getStories as getStoriesApi, StorieApiQueryOptions } from '@api/endpoints/stories';
import {
  incrementInteractions as incrementInteractionsApi,
  incrementViews as incrementViewsApi
} from '@api/endpoints/stories';
import { showErrorToast } from '@utils/toast';

interface StoriesType {
  id: string;
  source: {
    uri: string;
  };
  mediaType: string;
}

interface RentennialsStorie {
  id: string;
  imgUrl: string;
  name: string;
  stories: Array<StoriesType>;
  vehicle: {
    id: string;
  };
}

export const getStories = async (searchParams?: StorieApiQueryOptions): Promise<any> => {
  try {
    return await getStoriesApi(searchParams);
  } catch (e) {
    showErrorToast({ message: 'Hubo un error al cargar las historias' });
    return undefined;
  }
};

export const incrementInteractions = async (id: string) => {
  try {
    return await incrementInteractionsApi(id);
  } catch (error) {}
};

export const incrementViews = async (id: string) => {
  try {
    return await incrementViewsApi(id);
  } catch (error) {}
};
