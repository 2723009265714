import { LS_STORIES_IDS_KEY } from '@constants/index';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface StoriesType {
  id: string;
  source: {
    uri: string;
  };
  mediaType: string;
}

interface RentennialsStorie {
  [x: string]: any;
  id: string;
  imgUrl: string;
  name: string;
  stories: Array<StoriesType>;
  vehicle: {
    id: string;
  };
}

interface RentennialsTripIdsStoreType {
  stories: Array<RentennialsStorie>;
  setStories: (story: RentennialsStorie) => void;
  reset: () => void;
}

export const useRentennialsStories = create(
  persist<RentennialsTripIdsStoreType>(
    (set) => ({
      stories: [],
      setStories: (newStory) =>
        set((state) => ({
          stories: [newStory]
        })),
      reset: () => {
        set({
          stories: []
        });
      }
    }),
    { name: LS_STORIES_IDS_KEY }
  )
);
