import { sdkRequest } from '@api/sdk';
import { BASE_LISTING_URL } from '@config/index';
import { formatUrl } from 'url-lib';

export interface StorieApiQueryOptions {
  country?: string | undefined;
  city?: string | undefined;
}

export const getStories = async (searchParams?: StorieApiQueryOptions) => {
  const page = '1';
  const pageSize = '40';
  const urlParsed = formatUrl('stories', {
    page_size: pageSize,
    page: page,
    country: searchParams && searchParams.country,
    city: searchParams && searchParams.city
  });
  const response = await sdkRequest(urlParsed, undefined, BASE_LISTING_URL);
  return response;
};

export const incrementInteractions = async (id: string) => {
  const urlParsed = `stories/${id}/increment-interactions`;
  const response = await sdkRequest(
    urlParsed,
    {
      method: 'POST'
    },
    BASE_LISTING_URL
  );
  return response;
};

export const incrementViews = async (id: string) => {
  const urlParsed = `stories/${id}/increment-views`;
  const response = await sdkRequest(
    urlParsed,
    {
      method: 'POST'
    },
    BASE_LISTING_URL
  );
  return response;
};
